<template>
    <div
        class="grade-curricula"
    >
        <div
            v-if="maxLevels > 0"
            class="starting-level mb-8"
        >
            <label class="form-label text-right mr-2">
                {{ $root.translate("Starts at level") }}
            </label>
            <form-input
                id="starting_level"
                name="starting_level"
                type="number"
                :min="1"
                :max="maxLevels"
                v-model="record.starting_level"
                :required="true"
                :saving="saving('starting_level')"
                :error="has_error('starting_level')"
                class="ml-2"
                @input="save"
            />
        </div>

        <div
            v-for="curriculum in assigned_curricula"
            :key="curriculum.id"
            :class="{ 'mt-8': maxLevels <= 0 }"
            class="tile tile-centered"
        >
            <div
                class="tile-content"
                @click.prevent="edit_curriculum(curriculum.id)"
            >
                <div class="tile-title">{{ curriculum.name }} ({{ curriculum.province }})</div>
                <small class="tile-subtitle text-gray">
                    {{ $root.translate("1 outcome:::{n} outcomes", { n: outcomes_for_curriculum_id(curriculum.id).length }, outcomes_for_curriculum_id(curriculum.id).length) }}
                    &nbsp;&bull;&nbsp;
                    {{ $root.translate("1 achievement:::{n} achievements", { n: achievements_for_curriculum_id(curriculum.id).length }, achievements_for_curriculum_id(curriculum.id).length) }}
                </small>
            </div>
            <div class="tile-action">
                <default-button
                    flavor="link"
                    color="error"
                    size="sm"
                    @click.prevent="confirm_delete_curriculum(curriculum)"
                >
                    <open-icon glyph="trash" />
                </default-button>
            </div>
        </div>
        <div
            :class="{ 'mt-8': !!assigned_curricula.length }"
        >
            <div
                v-if="!!picking_curriculum"
                class="curriculum-picker"
            >
                <form-select
                    id="picked_curriculum"
                    name="picked_curriculum"
                    v-model="picked_curriculum"
                    label-field="display_name"
                    :data-source="available_curricula"
                    :required="true"
                />
                <default-button
                    @click.prevent="picking_curriculum = false"
                >
                    <open-icon glyph="times" />
                </default-button>
                <default-button
                    color="primary"
                    @click.prevent="curriculum_picked"
                >
                    <open-icon glyph="check" />
                </default-button>
            </div>
            <default-button
                v-else-if="!!available_curricula.length"
                flavor="link"
                :size="!!assigned_curricula.length ? 'sm' : 'md'"
                :block="true"
                @click.prevent="pick_curriculum"
            >
                <open-icon glyph="plus" class="mr-2" />{{ $root.translate("Add to another curriculum...") }}
            </default-button>
        </div>

        <modal-dialog
            id="activity-curriculum-editor"
            :show.sync="editing_curriculum"
            class="modal-lg"
        >
            <template v-slot:title>
                <span class="h5">{{ edited_curriculum.name }} ({{ edited_curriculum.province }}, {{ grade_by_id(record.grade).name }})</span>
            </template>

            <grade-curriculum-item
                :parent-id="0"
                :curriculum-id="editing_curriculum_id"
                :asset-grade="record"
                :selection="activity_grade_curriculum_items_for_curriculum_id(editing_curriculum_id)"
            />

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        @click.prevent="editing_curriculum=false"
                    >
                        {{ $root.translate("Done") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"
// import orderBy from "lodash/orderBy"

import { handles_saving, misc_utilities, confirms, profile_utilities } from "@/nibnut/mixins"
import { handles_grades } from "@/custom/mixins"

import { ModalDialog, FormInput, FormSelect, DefaultButton, OpenIcon } from "@/nibnut/components"
import GradeCurriculumItem from "./GradeCurriculumItem"

export default {
    name: "GradeCurricula",
    mixins: [handles_saving, misc_utilities, confirms, profile_utilities, handles_grades],
    components: {
        ModalDialog,
        FormInput,
        FormSelect,
        DefaultButton,
        OpenIcon,
        GradeCurriculumItem
    },
    mounted () {
        this.reset_ui()
    },
    watch: {
        "record.id": "reset_ui"
    },
    methods: {
        reset_ui () {
            this.record_id = this.record ? this.record.id : 0
        },
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) return this.save_field(this.record[field], field)
            }
            return Promise.resolve()
        },
        activity_grade_curriculum_items_for_curriculum_id (curriculum_id, type = null) {
            return this.raw_asset_grade_curriculum_items.filter(asset_grade_curriculum_item => {
                return (asset_grade_curriculum_item.computed_curriculum_id === curriculum_id) && (!type || (asset_grade_curriculum_item.computed_type === type))
            })
        },
        outcomes_for_curriculum_id (curriculum_id) {
            return this.activity_grade_curriculum_items_for_curriculum_id(curriculum_id, this.constants("curriculum_item_types", "TYPE_OUTCOME").id)
        },
        achievements_for_curriculum_id (curriculum_id) {
            return this.activity_grade_curriculum_items_for_curriculum_id(curriculum_id, this.constants("curriculum_item_types", "TYPE_ACHIEVEMENT").id)
        },
        pick_curriculum () {
            this.picked_curriculum = 0
            this.picking_curriculum = true
        },
        curriculum_picked () {
            this.picking_curriculum = false
            this.edit_curriculum(this.picked_curriculum)
        },
        edit_curriculum (curriculum_id) {
            this.editing_curriculum_id = curriculum_id
            this.editing_curriculum = true
        },
        confirm_delete_curriculum (curriculum) {
            this.action_record = curriculum
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: `${curriculum.name} (${curriculum.province}), ${this.grade_by_id(this.record.grade).name}` }),
                    message: this.$root.translate("Do you really want to delete this activity's curriculum? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-curriculum"
            )
        },
        confirmed () {
            if(this.confirming === "delete-curriculum") {
                this.$store.dispatch(
                    "RECORD_BULK_DELETE",
                    {
                        entity: "asset_grade_curriculum_item",
                        ids: this.raw_asset_grade_curriculum_items.filter(asset_grade_curriculum_item => asset_grade_curriculum_item.computed_curriculum_id === this.action_record.id).map(asset_grade_curriculum_item => asset_grade_curriculum_item.id)
                    }
                ).then(record => {
                    this.reset_ui()
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        raw_curriculum_items () {
            // list all curriculum_items whose subject_tag_id===subjectTagId and grade===record.grade
            return this.entity_records("curriculum_item").filter(curriculum_item => {
                return (curriculum_item.subject_tag_id === this.subjectTagId) && (curriculum_item.grade === this.record.grade)
            })
        },
        raw_asset_grade_curriculum_items () {
            return this.entity_records("asset_grade_curriculum_item").filter(asset_grade_curriculum_item => asset_grade_curriculum_item.asset_grade_id === this.record.id)
        },
        raw_curricula () {
            // list all curricula extracted from curriculum_items
            const curriculum_ids = this.raw_curriculum_items.map(curriculum_item => curriculum_item.curriculum_id)
            return this.entity_records("curriculum").filter(curriculum => curriculum_ids.indexOf(curriculum.id) >= 0)
        },
        assigned_curricula () {
            const curriculum_ids = this.raw_asset_grade_curriculum_items.map(asset_grade_curriculum_item => asset_grade_curriculum_item.computed_curriculum_id)
            return this.raw_curricula.filter(curriculum => curriculum_ids.indexOf(curriculum.id) >= 0)
        },
        available_curricula () {
            const curriculum_ids = this.raw_asset_grade_curriculum_items.map(asset_grade_curriculum_item => asset_grade_curriculum_item.computed_curriculum_id)
            return this.raw_curricula.filter(curriculum => curriculum_ids.indexOf(curriculum.id) < 0)
        },
        edited_curriculum () {
            if(!this.editing_curriculum_id) return {}
            return this.entity_record("curriculum", this.editing_curriculum_id)
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        subjectTagId: {
            type: Number,
            required: true
        },
        maxLevels: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "asset_grade",

            picking_curriculum: false,
            picked_curriculum: 0,

            editing_curriculum: false,
            editing_curriculum_id: 0,

            action_record: {}
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.grade-curricula {
    .starting-level {
        display: flex;
        align-items: center;
        max-width: 320px;
        margin: $unit-4 auto;

        & > label, & > div {
            flex: 1 1 50%;
        }
    }
    .tile {
        border: 1px solid $border-color;
        padding: $unit-1 $unit-2;
        margin: $unit-1 0;
    }

    .curriculum-picker {
        display: flex;

        & > .form-group {
            flex: 1 0 auto;
        }
    }

    ul {
        list-style: none;
    }
}
</style>
