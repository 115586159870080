<template>
    <page
        id="activities-list"
        :title="$root.translate('Activity:::Activities', {}, 2)"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <activities-list
            v-if="is_at_least_administrator"
        />
        <activities-grid
            v-else
            :can-preview="true"
            :can-bulk-assign="true"
        />
    </page>
</template>

<script type="text/javascript">
import { profile_utilities } from "@/nibnut/mixins"
import { is_page } from "@/custom/mixins"

import ActivitiesList from "./ActivitiesList"
import { ActivitiesGrid } from "@/custom/components"

export default {
    mixins: [is_page, profile_utilities],
    components: {
        ActivitiesList,
        ActivitiesGrid
    }
}
</script>
