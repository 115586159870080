<template>
    <div
        class="activity-curricula"
    >
        <div v-if="!activity || !activity.id" class="empty">
            <p class="empty-title h5">{{ $root.translate("Curricula") }}</p>
            <p class="empty-subtitle">{{ $root.translate("You will be able to add new curricula as soon as this activity is saved to the database.") }}</p>
        </div>
        <div v-else-if="!activity.grades.length" class="empty">
            <p class="empty-title h5">{{ $root.translate("No grades assigned") }}</p>
            <p class="empty-subtitle">{{ $root.translate("First, you need to assign this activity to at least one grade") }}</p>
            <div ref="dropdown_container" class="empty-action">
                <div
                    :class="{ 'active': menu_shown }"
                    class="dropdown"
                >
                    <default-button
                        class="dropdown-toggle"
                        @click.prevent="toggle_dropdown_menu"
                    >
                        {{ $root.translate("Pick a grade") }}
                        <open-icon glyph="caret-down" />
                    </default-button>
                    <ul class="menu">
                        <li
                            v-for="grade in available_grades"
                            :key="grade.id"
                            class="menu-item"
                        >
                            <base-link
                                href="#"
                                tabindex="0"
                                @click.prevent="grade_add(grade)"
                            >
                                {{ grade.name }}
                            </base-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else>
            <h6>{{ $root.translate("Curricula") }}</h6>

            <ul class="tab tab-block">
                <li
                    v-for="grade_id in activity.grades"
                    :key="grade_id"
                    :class="{ active: (current_grade_id === grade_id) }"
                    class="tab-item"
                >
                    <base-link
                        href="#"
                        @click.prevent="current_grade_id = grade_id"
                    >
                        {{ grade_by_id(grade_id).abbreviation }}
                    </base-link>
                    <default-button
                        flavor="link"
                        size="sm"
                        @click.prevent="grade_confirm_delete(grade_id)"
                    >
                        <open-icon glyph="trash" />
                    </default-button>
                </li>
                <li
                    ref="dropdown_container"
                    class="tab-item add-item"
                >
                    <div
                        :class="{ 'active': menu_shown }"
                        class="dropdown dropdown-right"
                    >
                        <default-button
                            class="dropdown-toggle"
                            @click.prevent="toggle_dropdown_menu"
                        >
                            <open-icon glyph="plus" />
                        </default-button>
                        <ul class="menu">
                            <li
                                v-for="grade in available_grades"
                                :key="grade.id"
                                class="menu-item"
                            >
                                <base-link
                                    href="#"
                                    tabindex="0"
                                    @click.prevent="grade_add(grade)"
                                >
                                    {{ grade.name }}
                                </base-link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <grade-curricula
                :record="asset_grade(current_grade_id)"
                :subject-tag-id="activity.subject_tag_id"
                :max-levels="(activity.type === constants('asset_types', 'TYPE_GAME').id) ? (parseInt(activity.number_of_levels) || 1) : 0"
            />
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { handles_grades } from "@/custom/mixins"
import { misc_utilities, confirms } from "@/nibnut/mixins"

import { DefaultButton, BaseLink, OpenIcon } from "@/nibnut/components"
import GradeCurricula from "./GradeCurricula"

export default {
    name: "Curricula",
    mixins: [handles_grades, misc_utilities, confirms],
    components: {
        DefaultButton,
        BaseLink,
        OpenIcon,
        GradeCurricula
    },
    mounted () {
        this.reset_ui()
    },
    beforeDestroy () {
        if(this.menu_shown) this.toggle_dropdown_menu()
    },
    watch: {
        "activity.id": "reset_ui"
    },
    methods: {
        reset_ui () {
            this.current_grade_id = this.activity.grades.length ? this.activity.grades[0] : -1
        },
        auto_close_dropdown_menu (event) {
            if(!!event && !!event.target && !!this.$refs.dropdown_container && !this.$refs.dropdown_container.contains(event.target)) {
                this.toggle_dropdown_menu()
            }
        },
        toggle_dropdown_menu () {
            if(this.menu_shown) {
                this.menu_shown = false
                document.removeEventListener("click", this.auto_close_dropdown_menu)
            } else {
                document.addEventListener("click", this.auto_close_dropdown_menu)
                this.menu_shown = true
            }
        },
        grade_add (grade) {
            this.toggle_dropdown_menu()
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: "asset_grade",
                    data: {
                        asset_id: this.activity.id,
                        grade: grade.id,
                        relation_ids: ["asset"]
                    }
                }
            ).then(() => {
                this.current_grade = grade.id
            }).catch(error => {
                this.$error(error.message)
            })
        },
        asset_grade (grade_id) {
            return this.raw_asset_grades.find(asset_grade => asset_grade.grade === grade_id) || {}
        },
        grade_confirm_delete (grade_id) {
            this.action_record = this.asset_grade(grade_id) || {}
            if(!this.action_record.id) return
            const grade = this.grade_by_id(grade_id)
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: grade.name }),
                    message: this.$root.translate("Do you really want to delete the curricula for {name}? All outcomes, achievements and statistics for this activity grade will be deleted. There is no undo...", { name: grade.name }),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-grade"
            )
        },
        confirmed () {
            if(this.confirming === "delete-grade") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "asset_grade",
                        id: this.action_record.id,
                        data: {
                            relation_ids: ["asset"]
                        }
                    }
                ).then(record => {
                    this.reset_ui()
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        raw_asset_grades () {
            return this.entity_records("asset_grade").filter(asset_grade => asset_grade.asset_id === this.activity.id)
        },
        asset_grades () {
            return orderBy(this.raw_asset_grades, "grade", "asc")
        },
        available_grades () {
            if(!this.activity || !this.activity.grades) return []
            return this.raw_grades.filter(grade => this.activity.grades.indexOf(grade.id) < 0)
        }
    },
    props: {
        activity: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            menu_shown: false,
            current_grade_id: -1,
            action_record: {}
        }
    }
}
</script>

<style lang="scss">
.activity-curricula {
    .empty {
        .dropdown {
            &:not(.active) .dropdown-toggle:focus + .menu {
                display: none;
            }
        }
        @media (hover: hover) {
            .dropdown:not(.hover-disabled):hover {
                display: inline-block;
            }
        }
    }
    .tab {
        .tab-item {
            position: relative;

            .btn-link {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }

            &.add-item {
                flex: 0 0 0;

                .btn {
                    border: 0;
                }
            }
        }
    }
}
</style>
