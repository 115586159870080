<template>
    <data-table
        v-if="is_at_least_administrator"
        id="activities-list"
        :columns="visible_columns"
        :rows="filtered_rows"
        :search="state.search"
        :total="state.total"
        :found="state.found"
        :current-page="state.page"
        :total-pages="nb_pages"
        :can-add="true"
        :export-url="export_url"
        @sort="sort_by"
        @search="search"
        @page="goto_page"
        @add="record_create('activity')"
        @click="record_edit('activity', $event.id)"
    >
        <template
            v-slot:controls
        >
            <div>
                <form-select
                    id="type"
                    name="type"
                    v-model="state.type"
                    :data-source="types"
                    :required="false"
                    :empty-label="translate('All types')"
                    @input="filter('type', $event)"
                />
            </div>
        </template>
        <template
            v-slot:summary="{ row }"
        >
            <h6>
                <open-icon :glyph="glyph_for_row(row)" class="mr-2" />
                {{ row.name }}
            </h6>
            <div class="text-gray text-uppercase">
                {{ row.subject_name }}
            </div>
            <div class="mt-2">
                {{ translate("Grade(s)") }}: {{ grades_for_row(row) }}
            </div>
        </template>
        <template
            v-slot:tbody="{ row, field }"
        >
            <span v-if="field === 'name'">
                <open-icon :glyph="glyph_for_row(row)" class="mr-2" />
                {{ row.name }}
            </span>
            <span v-else-if="field === 'grades'">
                {{ grades_for_row(row) }}
            </span>
            <span v-else>
                {{ row[field] }}
            </span>
        </template>
    </data-table>
</template>

<script type="text/javascript">
import { handles_grades } from "@/custom/mixins"
import { is_remote_data_table_source, crud_utilities } from "@/nibnut/mixins"

import {
    FormSelect,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_remote_data_table_source, crud_utilities, handles_grades],
    components: {
        FormSelect,
        OpenIcon
    },
    methods: {
        glyph_for_row (row) {
            if(row.type === this.constants("asset_types", "TYPE_GAME").id) return "gamepad"
            if(row.type === this.constants("asset_types", "TYPE_VIDEO").id) return "video"
            if(row.type === this.constants("asset_types", "TYPE_GLOSSARY").id) return "spell-check" // book
            return ""
        },
        grades_for_row (row) {
            return row.grades.map(grade => {
                return this.grade_by_id(grade).abbreviation
            }).join(", ")
        }
    },
    computed: {
        fields () {
            return [
                "type",
                "name",
                "subject_name",
                "grades"
            ]
        },
        types () {
            const audience_role = this.constants("roles", "ROLE_STUDENT").id
            return Object.values(this.constants("asset_types")).filter(asset_type => !asset_type.audience_role || (asset_type.audience_role === audience_role))
        }
    },
    data () {
        return {
            entity: "asset",
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                subject_name: { label: this.translate("Subject"), sort: null, type: "alpha" },
                grades: { label: this.translate("Grade(s)"), sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "type",
                filter: 0,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                role: 1
            }
        }
    }
}
</script>
