<template>
    <div
        :class="{ 'pb-2': !raw_group_users.length }"
        class="page-content-section bottom-section mt-4"
    >
        <div class="students-list-title mb-8">
            <h5 v-if="loading" class="text-center">
                <loader size="sm" />
            </h5>
            <h5 v-else>
                <span v-if="raw_group_users.length">{{ translate("1 Student:::{number} Students", { number: raw_group_users.length }, raw_group_users.length) }}</span>
                <span v-else>{{ translate("Students") }}</span>
                <default-button
                    v-if="is_at_least_administrator || !!profile_editable_groups.length"
                    flavor="link"
                    size="sm"
                    class="ml-2"
                    :title="translate('Add a New Student')"
                    @click.prevent="new_student"
                >
                    <open-icon glyph="plus" />
                </default-button>
                <default-button
                    v-if="!!profile.groups_to_archive && !!profile.groups_to_archive.length"
                    flavor="link"
                    size="sm"
                    :title="translate('Archive students before new school year')"
                    class="ml-2"
                    @click="$store.dispatch('SHOW_STUDENT_ARCHIVER', { show: true })"
                >
                    <open-icon glyph="archive" />
                </default-button>
                <default-button
                    v-if="!!profile.groups_to_manage && !!profile.groups_to_manage.length"
                    flavor="link"
                    size="sm"
                    :title="translate('Manage Student List')"
                    class="ml-2"
                    @click="$store.dispatch('SHOW_STUDENT_LIST_EDITOR', { show: true })"
                >
                    {{ translate('Manage Student List') }}
                </default-button>
                <default-button
                    v-else-if="is_at_least_administrator || !!profile_editable_groups.length"
                    flavor="link"
                    size="sm"
                    :title="translate('Add Multiple Students')"
                    class="add-multiple-students"
                    @click="$store.dispatch('SHOW_STUDENT_LIST_EDITOR', { show: true })"
                >
                    <open-icon :glyph="['user-friends', 'plus-circle']" />
                </default-button>
                <action-logs
                    v-if="!!group && !!group.id"
                    :group-id="group.id"
                    class="float-right"
                />
            </h5>
        </div>

        <table
            :class="{ 'table-scroll': !!raw_group_users.length && !loading }"
            class="table"
        >
            <thead>
                <tr v-if="loading">
                    <th>
                        <loader size="sm" />
                    </th>
                </tr>
                <tr v-else-if="!raw_group_users.length && !!group && !!group.name">
                    <th class="bg-gray">
                        <default-button
                            v-if="is_at_least_administrator || !!profile_editable_groups.length"
                            flavor="link"
                            :block="true"
                            @click.prevent="new_student"
                        >
                            {{ translate("Add your first student to group {name}", { name: group.name }) }}
                        </default-button>
                        <span v-else>
                            {{ translate("No students in group {name}", { name: group.name }) }}
                        </span>
                    </th>
                </tr>
                <tr v-else>
                    <th scope="row">
                        <base-link
                            v-if="!!group && !!group.id"
                            :href="{ name: 'group.access-cards', params: { id: group.id } }"
                            target="_blank"
                            :title="translate('Print All Access Cards')"
                            class="btn btn-link"
                        >
                            <open-icon glyph="id-card" :title="translate('Print All Access Cards')" /> {{ translate("Print All Access Cards") }}
                        </base-link>
                    </th>
                    <th
                        v-for="subject in subjects"
                        :key="subject.id"
                        class="col-topic"
                    >
                        <span class="dot" :style="{ 'background-color': subject.color }"></span>{{ translate_field(subject, 'name') }}
                    </th>
                </tr>
            </thead>
            <tbody v-if="!loading">
                <tr
                    v-for="group_user in group_users"
                    :key="group_user.id"
                >
                    <th scope="row">
                        <div class="tile tile-centered">
                            <div class="tile-icon">
                                <avatar :student="group_user" />
                            </div>
                            <div class="tile-content">
                                <div class="tile-title">
                                    {{ group_user.username }}
                                    <base-link
                                        :href="{ name: 'student.edit', params: { id: group_user.id } }"
                                        :title="translate('Student Profile')"
                                        class="ml-2"
                                    >
                                        <open-icon glyph="pen" :title="translate('Student Profile')" />
                                    </base-link>
                                </div>
                                <small class="tile-subtitle text-gray">
                                    {{ translate("Last login") }}:
                                    <span v-if="!!group_user.last_login_at">{{ $moment(group_user.last_login_at).fromNow() }}</span>
                                    <span v-else class="text-gray">({{ translate("Never") }})</span>
                                    &nbsp;&bull;&nbsp;
                                    {{ translate("Usage") }}:
                                    <span v-if="group_user.usage > 3600">{{ (group_user.usage / 3600) | nibnut.number("0,0.0") }} h</span>
                                    <span v-else-if="!!group_user.usage">{{ (group_user.usage / 60) | nibnut.number("0,0.0") }} min</span>
                                    <span v-else class="text-gray">0 min</span>
                                </small>
                            </div>
                            <div class="tile-action">
                                <base-link
                                    :href="{ name: 'group.access-cards', params: { id: groupId }, query: { student_id: group_user.id } }"
                                    target="_blank"
                                    :title="translate('Print Access Card')"
                                    class="btn btn-link btn-sm mr-1"
                                >
                                    <open-icon glyph="id-card" :title="translate('Print Access Card')" />
                                </base-link>
                                <base-link
                                    :href="{ name: 'stats', params: { group_id: groupId, user_id: group_user.user_id } }"
                                    target="_blank"
                                    :title="translate('Student Report')"
                                    class="btn btn-link btn-sm mx-1"
                                >
                                    <open-icon glyph="chalkboard" :title="translate('Student Report')" />
                                </base-link>
                                <base-link
                                    v-if="!!group_user.usage"
                                    :href="{ name: 'student.score-card', params: { id: group_user.user_id } }"
                                    target="_blank"
                                    :title="translate('Print Score Card')"
                                    class="btn btn-link btn-sm ml-1"
                                >
                                    <open-icon glyph="certificate" :title="translate('Print Score Card')" />
                                </base-link>
                            </div>
                        </div>
                    </th>
                    <td
                        v-for="subject in subjects"
                        :key="subject.id"
                        class="col-topic"
                    >
                        <div>
                            <activity-progress
                                v-for="assignment in assignments_for_student_topic(group_user.id, subject.id)"
                                :key="assignment.id"
                                :assignment="assignment"
                                :class="{ clickable: !!assignment.computed_last_seen_at }"
                                @click.native="edit_or_replay(group_user, assignment)"
                            />
                            <default-button
                                class="s-circle"
                                @click.prevent="assign_activity(group_user, subject.id)"
                            >
                                <open-icon glyph="plus" />
                            </default-button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <replay-dialog
            :show.sync="replaying"
            :student="replay_student"
            :assignment-id="replay_assignment.id || 0"
        />

        <assign-dialog
            v-if="!!assignment"
            :assignment="assignment"
            :student="assignment_student"
            :user="assignment_user"
            :subject-tag-id="assignment_subject_tag_id"
            :show.sync="assigning"
            :fields="['fieldset::dashboard']"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import is_dashboard_panel from "./IsDashboardPanel"

import { BaseLink, DefaultButton, OpenIcon } from "@/nibnut/components"
import { ActionLogs, ActivityProgress, Loader, Avatar } from "@/custom/components"
import { AssignDialog, ReplayDialog } from "@/custom/dialogs"

let autoloader = null

export default {
    name: "BottomSection",
    mixins: [is_dashboard_panel],
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon,
        Loader,
        ActionLogs,
        AssignDialog,
        ReplayDialog,
        Avatar,
        ActivityProgress
    },
    mounted () {
        this.reload_students()
    },
    beforeDestroy () {
        this.clear_autoloader()
    },
    watch: {
        groupId: "reload_students"
    },
    methods: {
        clear_autoloader () {
            if(autoloader) {
                clearTimeout(autoloader)
                autoloader = null
            }
        },
        load_students (auto = false) {
            this.clear_autoloader()
            if(this.profile_id && this.is_at_least_teacher) {
                if(auto) this.auto_loading = true
                else this.loading = true
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "group_user",
                        query: {
                            group_id: this.groupId,
                            relation_ids: ["assignment"],
                            fields: ["fieldset::dashboard", "ns::assignment;fieldset::dashboard"]
                        }
                    }
                ).then(() => {
                    autoloader = setTimeout(() => {
                        this.load_students(true)
                    }, 5 * 60 * 1000)
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                    this.auto_loading = false
                })
            }
        },
        reload_students () {
            this.load_students(false)
        },
        assignments_for_student_topic (group_user_id, subject_tag_id) {
            const type_id = this.constants("asset_types", "TYPE_GAME").id
            const assignments = this.entity_records("assignment").filter(assignment => {
                return (assignment.group_user_id === group_user_id) && (assignment.computed_subject_tag_id === subject_tag_id) && (assignment.asset_type === type_id)
            })
            return orderBy(assignments, "computed_last_seen_at", "asc")
        },
        new_student () {
            this.$router.push({ name: "student.edit", params: { id: 0, group_id: this.groupId } })
        },
        assign_activity (group_user, subject_tag_id = 0) {
            this.assignment_student = { ...group_user }
            this.$store.dispatch(
                "FETCH_RECORD",
                {
                    entity: "user",
                    id: this.assignment_student.user_id,
                    query: {
                        fields: ["fieldset::group_user"]
                    },
                    reset: true
                }
            ).then(user => {
                this.assignment_user = { ...user }

                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    {
                        entity: "assignment",
                        reset: true
                    }
                ).then(assignment => {
                    this.assignment = { ...assignment }
                    this.assignment.group_user_id = this.assignment_student.id
                    const subject_grade = this.assignment_user.subject_grades.find(subject_grade => subject_grade.subject_tag_id === subject_tag_id) || { grade: 0 }
                    this.assignment.grade = subject_grade.grade
                    this.assignment_subject_tag_id = subject_tag_id

                    this.assigning = true
                }).catch(error => {
                    this.$error(error.message)
                })
            }).catch(error => {
                this.$error(error.message)
            })
        },
        edit_or_replay (student, assignment) {
            if(!assignment.computed_last_seen_at) {
                this.assignment_student = { ...student }

                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "user",
                        id: student.user_id,
                        data: {
                            fields: ["fieldset::group_user"]
                        },
                        reset: true
                    }
                ).then(user => {
                    this.assignment_user = { ...user }
                    this.assignment = { ...assignment }
                    this.assignment.group_user_id = student.id
                    this.assignment_subject_tag_id = this.assignment.computed_subject_tag_id

                    this.assigning = true
                }).catch(error => {
                    this.$error(error.message)
                })
            } else {
                this.replay_assignment = assignment
                this.replay_student = student
                this.replaying = true
            }
        }
    },
    computed: {
        subjects () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_SUBJECT").id
            return orderBy(this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy), "name", "asc")
        }
    },
    data () {
        return {
            fetching: false,
            loading: false,

            assigning: false,
            assignment: null,
            assignment_student: null,
            assignment_user: null,
            assignment_subject_tag_id: 0,

            replay_assignment: {},
            replay_student: {},
            replaying: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#replay-dialog {
    .timeline {
        img {
            height: 2em;
            vertical-align: text-bottom;

            &.border-success,
            &.border-error,
            &.border-gray {
                border: $border-width solid transparent;
            }
            &.border-success {
                border-color: $success-color;
            }
            &.border-error {
                border-color: $error-color;
            }
            &.border-gray {
                border-color: $gray-color;
            }
        }
    }
}
.btn.add-multiple-students {
    .la-stack {
        .la-stack-1x:first-child {
            font-size: 2em;
        }
        .la-stack-1x:last-child {
            left: auto;
            right: -0.2em;
            width: auto;
            bottom: 0.2em;
            background: white;
            border-radius: 50%;
            line-height: 0.8em;
            font-size: 1em;
        }
    }
}
</style>
