<template>
    <page
        id="group-editor"
        :title="page_title"
        :back-navigation-fallback="{ title: translate(entityName, {}, 2), href: { name: 'group.list' } }"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            @submit="create"
        >
            <div class="card">
                <div class="card-body">
                    <div class="columns">
                        <div
                            class="column col-12"
                        >
                            <div class="columns">
                                <div
                                    class="column col-2 col-lg-4 col-md-6 col-sm-12"
                                >
                                    <avatar-input
                                        id="avatar_id"
                                        name="avatar_id"
                                        v-model="edited_record.avatar_id"
                                        :editable="administers_group"
                                        @input="save"
                                    />
                                </div>
                                <div
                                    class="column col-10 col-lg-8 col-md-6 col-sm-12"
                                >
                                    <form-input
                                        id="name"
                                        name="name"
                                        v-model="edited_record.name"
                                        :required="true"
                                        :editable="administers_group"
                                        :error="has_error('name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Name") }}</template>
                                    </form-input>

                                    <form-select
                                        id="curriculum_id"
                                        name="curriculum_id"
                                        v-model="edited_record.curriculum_id"
                                        :context-id="edited_record.state || profile.state"
                                        :data-source="entity"
                                        :required="true"
                                        :editable="administers_group"
                                        :show-all="true"
                                    >
                                        <template v-slot:label>{{ translate("Curriculum") }}</template>
                                    </form-select>

                                    <form-dropdown
                                        id="grade"
                                        name="grade"
                                        :value="edited_record.grade"
                                        :options="available_grades"
                                        :empty-value="-1"
                                        :empty-label="translate('No Grade Assigned')"
                                        :required="false"
                                        :editable="administers_group"
                                        :error="has_error('grade')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Grade") }}</template>
                                    </form-dropdown>

                                    <group-access-code
                                        v-if="!!edited_record && !!edited_record.id && !!edited_record.access_code"
                                        :group="edited_record"
                                        class="mt-2"
                                    />
                                </div>
                            </div>

                            <div
                                v-if="owns_group"
                                class="text-center mt-8"
                            >
                                <default-button
                                    flavor="link"
                                    color="error"
                                    size="sm"
                                    @click.prevent="confirm_record_delete"
                                >
                                    {{ translate("Delete Group") }}
                                </default-button>
                            </div>
                        </div>
                        <div
                            class="column col-12"
                        >
                            <h6>{{ translate("Collaborators") }}</h6>

                            <collaborators
                                :group="edited_record"
                                :records="collaborators"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="!edited_record.id"
                    class="card-footer text-center"
                >
                    <default-button
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="$router.go(-1)"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="!!creating"
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="create"
                    >
                        {{ translate("Save") }}
                    </default-button>
                </div>
            </div>
            <div
                v-if="!!edited_record.id"
                class="card mt-8"
            >
                <div class="card-body">
                    <h6>{{ translate("Students") }}</h6>

                    <students-list
                        :group="edited_record"
                    />
                </div>
            </div>
        </base-form>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor, handles_grades } from "@/custom/mixins"

import {
    BaseForm,
    FormInput,
    FormSelect,
    FormDropdown,
    DefaultButton
} from "@/nibnut/components"
import { AvatarInput, StudentsList, GroupAccessCode } from "@/custom/components"
import Collaborators from "./Collaborators"

export default {
    mixins: [is_record_editor, handles_grades],
    components: {
        BaseForm,
        FormInput,
        FormSelect,
        FormDropdown,
        DefaultButton,
        AvatarInput,
        StudentsList,
        GroupAccessCode,
        Collaborators
    },
    methods: {
        create () {
            this.creating = true
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: "group",
                    data: this.edited_record
                }
            ).then(record => {
                this.$store.dispatch("HISTORY_POP", {})
                this.$router.replace({ name: `${this.entity}.edit`, params: { id: record.id } })
            }).catch(this.receive_error).then(() => {
                this.creating = false
            })
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Group"),
                    message: this.translate("Do you really want to delete this group? You will lose access to all its students. There is no undo!"),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_record.id
                    }
                ).then(() => {
                    this.done_confirming()
                    this.$store.dispatch("HISTORY_POP", {})
                    this.$router.replace({ name: "group.list" })
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        }
    },
    computed: {
        reload_query () {
            return {
                relation_ids: this.relation_ids,
                fields: ["fieldset::default", "ns::group_user;fieldset::group"]
            }
        },
        collaborators () {
            if(!this.profile_id || !this.edited_record || !this.edited_record.id) return []
            const min_role = this.constants("group_user_types", "ROLE_OBSERVER").id
            return this.group_users_by_group_id(this.edited_record.id).filter(group_user => {
                return group_user.role >= min_role
            })
        },
        administers_group () {
            if(!this.profile_id || !this.edited_record || !this.edited_record.id) return !!this.edited_record && !this.edited_record.id
            const min_role = this.constants("group_user_types", "ROLE_ADMIN").id
            return !!this.group_users_by_group_id(this.edited_record.id).find(group_user => {
                return (group_user.role >= min_role) && (group_user.user_id === this.profile_id)
            })
        },
        owns_group () {
            const group_user = this.group_users_by_group_id(this.edited_record.id).find(group_user => {
                return (group_user.user_id === this.profile_id) && (this.edited_record.owner_id === group_user.id)
            })
            return this.is_at_least_administrator || !!group_user
        }
    },
    data () {
        return {
            entity: "group",
            entityName: "Group:::Groups",
            relation_ids: ["group_user"],
            creating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#group-editor {
    .avatar-input {
        position: relative;
        z-index: $zindex-1;
    }
}
</style>
