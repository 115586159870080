<template>
    <page
        id="dashboard"
        :title="$root.translate('Dashboard')"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        :multi-boxes="true"
        @statused="statused"
    >
        <template v-slot:title>
            <div class="columns dashboard-header">
                <div class="column col-6">
                    <h1 v-if="!!$route && (!$route.meta || !$route.meta.student_route)">{{ $root.translate('Dashboard') }}</h1>
                </div>
                <div class="column col-6 text-right">
                    <default-button
                        v-if="!!news.length"
                        flavor="link"
                        @click.prevent="show_news"
                    >
                        {{ $root.translate("What's New?") }}
                    </default-button>
                </div>
            </div>
        </template>

        <div
            v-if="!profile_group_users.length && profile_is_subscribed"
            class="empty"
        >
            <div class="empty-icon">
                <open-icon glyph="users" size="5x" />
            </div>
            <p class="empty-title h5">{{ $root.translate("First things first...") }}</p>
            <p class="empty-subtitle">{{ $root.translate("Before you can add students to your account, you need to create at least one group.") }}</p>
            <div class="empty-action">
                <default-button
                    color="primary"
                    @click.prevent="group_new"
                >
                    {{ $root.translate("Create my first group...") }}
                </default-button>
            </div>
        </div>

        <top-section
            v-if="!!profile_group_users.length && profile_is_subscribed"
            :group-id="group_id"
            :group-ids="group_ids"
            @waiting="waiting = $event"
            @loaded="group_ids = $event"
            @input="group_id = $event"
        />

        <bottom-section
            v-if="!!profile_group_users.length && profile_is_subscribed"
            :group-id="group_id"
        />

        <div class="float-right mt-4">
            <form-toggle-input
                id="school_network"
                name="school_network"
                type="checkbox"
                :value="valid_ip"
                :required="false"
                class="text-gray"
                @input="save_profile_ip($event ? 'valid_ips' : 'invalid_ips')"
            >
                {{ $root.translate("Currently on my school's network") }}
                <help-button
                    size="sm"
                    class="popover-bottom"
                >
                    <p>{{ $root.translate("We use this information to determine if your students are using Sam Amuse at school or not.") }}</p>
                </help-button>
            </form-toggle-input>
        </div>

        <modal-dialog
            id="news-reader"
            :show.sync="reading_news"
            class="modal-lg"
        >
            <template v-slot:title>
                <div class="h5 text-center">
                    <app-logo />
                    {{ $root.translate("What's New In Sam Amuse's Universe?") }}
                </div>
            </template>

            <div
                v-for="article in news"
                :key="article.id"
                class="card"
                @click.prevent="goto_article(article)"
            >
                <div
                    v-if="!!article.image"
                    class="card-image"
                >
                    <img :src="article.image" class="img-responsive">
                </div>
                <div class="card-body">
                    <div class="card-title h5">{{ article.title }}</div>
                    <div class="card-subtitle text-gray text-small">{{ article.date | nibnut.date }}</div>
                    <div class="mt-4">
                        {{ article.content }}
                    </div>
                    <div class="text-right mt-2">
                        <a
                            v-if="!!article.url"
                            :id="`news-reader-article-${article.id}`"
                            :href="article.url"
                            target="_blank"
                            @click.stop
                        >
                            {{ $root.translate("Read more") }}...
                        </a>
                    </div>
                </div>
            </div>
        </modal-dialog>

        <new-group-dialog
            :show.sync="creating_group"
            :record="new_group"
        />
    </page>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { misc_utilities } from "@/nibnut/mixins"
import { is_page, addl_profile_utilities } from "@/custom/mixins"

import { ModalDialog, FormToggleInput, DefaultButton, HelpButton, OpenIcon, AppLogo } from "@/nibnut/components"
import { NewGroupDialog } from "@/custom/dialogs"
import TopSection from "./TopSection"
import BottomSection from "./BottomSection"

export default {
    mixins: [addl_profile_utilities, misc_utilities, is_page],
    components: {
        ModalDialog,
        FormToggleInput,
        DefaultButton,
        HelpButton,
        OpenIcon,
        AppLogo,
        NewGroupDialog,
        TopSection,
        BottomSection
    },
    watch: {
        $route: "load_news",
        profile_id: "load_news",
        showingPostLoginScreen: "maybe_show_news"
    },
    methods: {
        load_news () {
            if(this.profile_id) {
                const domain = this.setting("website_domain")
                if(domain) {
                    this.$store.dispatch(
                        "FETCH_REMOTE_DATA",
                        { url: `https://${domain}/wp-json/saw-plugin/v1/news` }
                    ).then(response => {
                        this.news = response || []
                        this.maybe_show_news()
                    })
                }
            }
        },
        maybe_show_news () {
            if(this.has_unread_news) this.show_news()
        },
        show_news () {
            if(!this.showingPostLoginScreen && !!this.news.length) {
                this.$store.dispatch("RECORD_SAVE", {
                    entity: "user",
                    id: this.profile_id,
                    data: { news_read_until: this.$moment(this.news[0].date).format("YYYY-MM-DD HH:mm:ss") }
                })
                this.reading_news = true
            }
        },
        goto_article (article) {
            const link = document.getElementById(`news-reader-article-${article.id}`)
            if(link) link.click()
        },
        group_new () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                {
                    entity: "group",
                    reset: true
                }
            ).then(record => {
                this.new_group = { ...record, user_id: this.profile_id }
                this.creating_group = true
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    computed: {
        ...mapState(["ip"]),
        has_unread_news () {
            if(this.profile_id && this.news.length) {
                if(!this.profile.news_read_until) return true

                const news_read_until = this.$moment(this.profile.news_read_until)
                return !!this.news.find(news => this.$moment(news.date).isAfter(news_read_until))
            }
            return false
        }
    },
    props: {
        showingPostLoginScreen: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            group_id: 0,
            group_ids: [],
            news: [],
            reading_news: false,

            creating_group: false,
            new_group: {}
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#dashboard {
    .dashboard-header {
        align-items: center;
    }
    .avatar {
        background-color: $bg-color;
    }
    .page-body {
        padding-bottom: $unit-4;

        .page-content-section {
            margin-bottom: 0;
            padding: $layout-spacing-lg $layout-spacing-lg 0 $layout-spacing-lg;

            &.top-section {
                img {
                    max-height: 3 * $line-height;
                }
                .group-info {
                    display: flex;

                    & > div {
                        flex: 1 0 auto;
                    }
                }
                .access-code {
                    padding: 0;

                    & > .group-access-code {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        height: 100%;

        & > .card-header {
            img {
                max-height: 3 * $line-height;
            }
            &.group-info {
                display: flex;

                & > div {
                    flex: 1 0 auto;
                }
            }
            &.access-code {
                padding: 0;

                & > .group-access-code {
                    width: 100%;
                    text-align: center;
                }
            }
            &.students-list {
                display: flex;
                align-items: center;
                margin-bottom: $unit-4;

                h5 {
                    flex: 1 0 auto;
                    margin-bottom: 0;
                }
            }
        }
        & > .card-body {
            flex: 1 0 auto;
            max-height: 50vh;
            overflow-y: auto;

            .tile {
                border-bottom: 1px solid $border-color;
                padding-bottom: $unit-2;
                margin-bottom: $unit-2;

                .tile-action {
                    .menu {
                        font-size: 0.8em;
                        white-space: nowrap;
                    }
                }

                &:last-child {
                    border-bottom-color: transparent;
                    margin-bottom: 0;
                }
            }
        }
    }
    .table {
        min-width: 100%;
        max-height: 60vh;

        .col-topic {
            min-width: 350px;
            white-space: nowrap;
        }
        thead {
            th {
                position: sticky;
                top: 0;
                background-color: white;
                text-align: center;
                border-right: 1px solid $border-color;
                z-index: 2;

                &[scope=row] {
                    left: 0;
                    z-index: 3;
                }
                &:last-child {
                    border-right: 0px;
                }
            }
        }
        tbody {
            th[scope=row],
            td {
                border-right: 1px solid $border-color;

                &:last-child {
                    border-right: 0px;
                }
            }
            th[scope=row] {
                position: sticky;
                left: 0;
                background-color: white;
                border-bottom-width: 1px;
                z-index: 1;
            }
            td > div {
                display: flex;
                justify-content: center;
                align-items: center;

                & > .activity-progress-container {
                    margin: 0 $unit-2;
                }
                & > button.btn {
                    width: 30px;
                    height: 30px;
                    border-color: $bg-color;
                    background-color: $bg-color;
                    line-height: 13px;
                    margin: 0 $unit-2;

                    .lar, .las, .lab { font-size: 0.65rem; }
                }
            }
        }
    }

    #replay-dialog {
        .student-info {
            display: flex;

            & > figure {
                flex: 0 0 auto;
            }
        }
    }
    #news-reader {
        .modal-overlay {
            background: rgba(0, 0, 0, 0.85);
        }
        .modal-title {
            position: relative;
            pointer-events: none;

            .app-logo {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-5.5em, -4.5em);
                height: 8em;
            }
        }
        .modal-body {
            .card {
                flex-direction: row;
            }
        }
    }
}
</style>
