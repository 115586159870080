<template>
    <page
        id="coupons-list"
        :title="translate('Coupon:::Coupons', {}, 2)"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <data-table
            id="coupons-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create(entity)"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6
                    :class="{ 'text-gray': !row.is_valid }"
                >
                    {{ row.name }}
                </h6>
                <div
                    :class="{ 'text-gray': !row.is_valid }"
                    class="my-2"
                >
                    {{ coupon_terms(row) }}
                </div>
                <div
                    :class="{ 'text-gray': !row.is_valid }"
                    class="my-2"
                >
                    <strong>{{ translate("Redemptions") }}: </strong>
                    {{ row.computed_redemptions | nibnut.number("0,0") }}<span v-if="row.max_redemptions">/{{ row.max_redemptions | nibnut.number("0,0") }}</span>
                </div>
                <div
                    :class="{ 'text-gray': !row.is_valid }"
                    class="my-2"
                >
                    <strong>{{ translate("Expires") }}: </strong>
                    <span v-if="!!row.redeem_by">{{ row.redeem_by | nibnut.date }}</span>
                    <span v-else>&mdash;</span>
                </div>
                <default-button
                    flavor="link"
                    :title="translate('Copy Code')"
                    :disabled="!row.is_valid"
                    @click.prevent.stop
                    v-clipboard:copy="row.stripe_id"
                    v-clipboard:success="clipboard_copied"
                >
                    <open-icon glyph="clipboard" /> {{ row.stripe_id }}
                </default-button>
                <default-button
                    color="error"
                    :block="true"
                    :title="translate('Delete')"
                    @click.prevent="confirm_record_delete(row)"
                >
                    <open-icon glyph="trash" /> {{ translate('Delete') }}
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <default-button
                    v-if="field === 'stripe_id'"
                    flavor="link"
                    :title="translate('Copy Code')"
                    :disabled="!row.is_valid"
                    @click.prevent.stop
                    v-clipboard:copy="row.stripe_id"
                    v-clipboard:success="clipboard_copied"
                >
                    <open-icon glyph="clipboard" /> {{ row.stripe_id }}
                </default-button>
                <div v-else-if="field === 'name'" class="columns">
                    <div class="column col-6">
                        <form-input
                            :id="`name-${row.id}`"
                            name="name"
                            v-model="row.name"
                            :required="true"
                            :placeholder="translate('English')"
                            :error="has_error('name')"
                            @input="save_field_for_record_id(entity, row.id, $event, 'name')"
                        />
                    </div>
                    <div class="column col-6">
                        <form-input
                            :id="`fr-name-${row.id}`"
                            name="name"
                            :value="field_translation_for_classname(classname, row.id, 'name').value"
                            :required="false"
                            :placeholder="translate('French')"
                            @input="save_field_translation_for_classname(classname, row.id, 'name', $event)"
                        />
                    </div>
                </div>
                <span
                    v-else-if="field === 'amount_off'"
                    :class="{ 'text-gray': !row.is_valid }"
                >
                    {{ coupon_terms(row) }}
                </span>
                <span
                    v-else-if="field === 'computed_redemptions'"
                    :class="{ 'text-gray': !row.is_valid }"
                >
                    {{ row.computed_redemptions | nibnut.number("0,0") }}<span v-if="row.max_redemptions">/{{ row.max_redemptions | nibnut.number("0,0") }}</span>
                </span>
                <span
                    v-else-if="field === 'redeem_by'"
                    :class="{ 'text-gray': !row.is_valid }"
                >
                    <span v-if="!!row.redeem_by">{{ row.redeem_by | nibnut.date }}</span>
                    <span v-else>&mdash;</span>
                </span>
                <div
                    v-else-if="field === '_buttons'"
                    class="text-right"
                >
                    <default-button
                        flavor="link"
                        color="error"
                        size="sm"
                        :title="translate('Delete')"
                        @click.prevent="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" />
                    </default-button>
                </div>
                <span
                    v-else
                    :class="{ 'text-gray': !row.is_valid }"
                >
                    {{ row[field] }}
                </span>
            </template>
        </data-table>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_page, has_translatable_fields } from "@/custom/mixins"
import { is_remote_data_table_source, crud_utilities, handles_saving, string_utilities, confirms } from "@/nibnut/mixins"

import {
    FormInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, handles_saving, string_utilities, confirms, has_translatable_fields],
    components: {
        FormInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        confirm_record_delete (row) {
            this.action_record = row
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: row.name }),
                    message: this.translate("Do you really want to delete this coupon? New signups will not be able to use it, but existing subscriptions who applied the discount will still benefit from it. There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.action_record.id
                    }
                ).then(record => {
                    this.record_deleted(record)
                    this.done_confirming()
                }).catch(this.receive_error)
            } else this.done_confirming()
        },
        record_deleted () {
            this.action_record = {}
        },
        clipboard_copied () {
            this.$success(this.translate("Code Copied!"))
        }
    },
    computed: {
        fields () {
            return [
                "fieldset::default"
            ]
        }
    },
    data () {
        return {
            entity: "coupon",
            classname: "App\\Coupon",
            columns: {
                stripe_id: { label: this.translate("Code"), sort: null, type: "alpha" },
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                amount_off: { label: this.translate("Terms"), sort: false },
                computed_redemptions: { label: this.translate("Redemptions"), sort: null, type: "numeric" },
                redeem_by: { label: this.translate("Expires"), sort: null, type: "numeric" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            action_record: {}
        }
    }
}
</script>
