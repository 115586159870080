<template>
    <div class="page-content-section top-section">
        <div class="columns">
            <div class="column col-3 col-lg-12">
                <div class="group-info">
                    <img :src="group.image_url" class="mr-4" />
                    <div>
                        <form-dropdown
                            id="group_id"
                            name="group_id"
                            :value="state.group_id"
                            :options="groups"
                            :required="true"
                            :editable="groups.length > 1"
                            @input="set_group_id($event)"
                        />
                        <div class="text-gray columns">
                            <div class="column col-4 col-lg-12">{{ grade_by_id(group.grade).name }}</div>
                            <div class="column col-8 col-lg-12 text-right">
                                <base-link
                                    v-if="is_at_least_administrator || !!profile_editable_groups.length"
                                    :href="{ name: 'profile', hash: '#institutions-and-groups' }"
                                    :title="$root.translate('Manage my groups')"
                                >
                                    {{ $root.translate("Manage my groups") }}
                                </base-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="access-code">
                    <group-access-code
                        v-if="!!group && !!group.access_code"
                        :group="group"
                        class="mt-8"
                    />
                </div>
            </div>
            <div
                v-if="!tiny_screen"
                :class="{ 'mt-4': small_screen }"
                class="column col-9 col-lg-12"
            >
                <summary-graphs
                    v-if="!!profile_id"
                    entity="group"
                    :entity-id="state.group_id || 0"
                    class="graphs"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ui_utilities, handles_stored_state } from "@/nibnut/mixins"
import { handles_grades } from "@/custom/mixins"
import is_dashboard_panel from "./IsDashboardPanel"

import { FormDropdown, BaseLink } from "@/nibnut/components"
import { SummaryGraphs, GroupAccessCode } from "@/custom/components"

export default {
    name: "TopSection",
    mixins: [handles_stored_state, handles_grades, ui_utilities, is_dashboard_panel],
    components: {
        FormDropdown,
        BaseLink,
        SummaryGraphs,
        GroupAccessCode
    },
    mounted () {
        this.boostrap()
    },
    watch: {
        $route: "boostrap",
        profile_id: "boostrap"
    },
    methods: {
        boostrap () {
            if(this.profile_id && this.is_at_least_teacher) {
                this.$emit("waiting", true)
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "group",
                        query: {
                            user_id: this.profile.id,
                            fields: ["fieldset::dashboard"]
                        }
                    }
                ).then(response => {
                    this.$emit("loaded", response.record_ids)
                    this.$nextTick(() => {
                        const groups = this.groups
                        // if(!groups.length) this.$router.replace({ name: "group.list" })
                        // else {
                        if(groups.length) {
                            const group = groups.find(group => group.id === this.state.group_id) || groups[0]
                            if(group) this.set_group_id(group.id)
                        }
                    })
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.$emit("waiting", false)
                })
            }
        },
        set_group_id (group_id) {
            this.set_state_value("group_id", group_id)
            this.$emit("input", group_id)
        }
    },
    computed: {
        state_identifier () {
            return "dashboard"
        }
    },
    data () {
        return {
            default_state: {
                group_id: 0
            }
        }
    }
}
</script>
