<template>
    <page
        id="activity-editor"
        :title="page_title"
        :back-navigation-fallback="{ title: translate(entityName, {}, 2), href: { name: 'activity.list' } }"
        :status="page_status"
        :waiting="waiting"
        :multi-boxes="true"
        @statused="statused"
    >
        <template v-slot:title>
            <div class="page-title">
                <h1>{{ page_title }}</h1>
                <form-toggle-input
                    v-if="!!edited_record"
                    id="publish_at"
                    name="publish_at"
                    type="checkbox"
                    :value="!!edited_record.publish_at"
                    @input="toggle_publish_at"
                >
                    {{ translate("Published") }}
                </form-toggle-input>
            </div>
        </template>

        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            @submit="create"
        >
            <div class="card page-content-section">
                <div class="card-body">
                    <div class="columns">
                        <div class="column col-7 col-md-6 col-sm-12">
                            <div class="columns">
                                <div class="column col-4 col-md-5 col-sm-12">
                                    <div
                                        class="nibnut-aspect-ratio-box nibnut-ratio-square"
                                    >
                                        <div>
                                            <image-upload-input
                                                v-if="!!edited_record"
                                                id="image_url"
                                                name="image_url"
                                                :url="edited_record.image_url || held_images.image_url || ''"
                                                :alt="edited_record.name || ''"
                                                :accepts="upload_accepts('image_url')"
                                                :uploading="!!uploading.image_url"
                                                :uploaded="uploaded.image_url||0"
                                                :error="has_error('image_url')"
                                                @upload="upload_file_list('image_url', $event)"
                                                @clear="confirm_clear_file('image_url')"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        v-if="!!edited_record.id"
                                        class="text-center mt-8"
                                    >
                                        <default-button
                                            flavor="link"
                                            color="error"
                                            size="sm"
                                            @click.prevent="confirm_record_delete"
                                        >
                                            {{ translate("Delete Activity") }}
                                        </default-button>
                                    </div>
                                </div>
                                <div class="column col-8 col-md-7 col-sm-12">
                                    <div class="columns">
                                        <div class="column col-6 col-sm-12">
                                            <form-dropdown
                                                id="type"
                                                name="type"
                                                v-model="edited_record.type"
                                                :options="types"
                                                :required="true"
                                                :disabled="!!edited_record.id"
                                                :saving="saving('type')"
                                                :error="has_error('type')"
                                                @input="save"
                                            >
                                                <template v-slot:label>{{ translate("Type") }}</template>
                                            </form-dropdown>
                                        </div>
                                        <div class="column col-6 col-sm-12">
                                            <form-dropdown
                                                id="subject_tag_id"
                                                name="subject_tag_id"
                                                v-model="edited_record.subject_tag_id"
                                                :options="subjects"
                                                :required="true"
                                                :disabled="!!edited_record.id && edited_record.is_assigned"
                                                :saving="saving('subject_tag_id')"
                                                :error="has_error('subject_tag_id')"
                                                @input="save"
                                            >
                                                <template v-slot:label>{{ translate("Subject") }}</template>
                                            </form-dropdown>
                                        </div>
                                    </div>
                                    <form-input
                                        id="name"
                                        name="name"
                                        v-model="edited_record.name"
                                        :required="true"
                                        :disabled="!!edited_record.id"
                                        :saving="saving('name')"
                                        :error="has_error('name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Name") }}</template>
                                    </form-input>
                                    <form-tag-input
                                        v-if="edited_record.type === constants('asset_types', 'TYPE_GAME').id"
                                        id="equipment_ids"
                                        name="equipment_ids"
                                        v-model="edited_record.equipment_ids"
                                        data-source="tag"
                                        :required="false"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Equipment") }}</template>
                                    </form-tag-input>

                                    <div
                                        v-if="edited_record.type !== constants('asset_types', 'TYPE_GAME').id"
                                    >
                                        <form-dropdown
                                            v-if="languages.length > 1"
                                            id="lang"
                                            name="lang"
                                            :value="edited_record.lang"
                                            :options="languages"
                                            :required="true"
                                            @input="save"
                                        >
                                            <template v-slot:label>{{ translate("Language") }}</template>
                                        </form-dropdown>
                                    </div>

                                    <video-input
                                        v-if="is_video"
                                        id="settings"
                                        name="settings"
                                        v-model="edited_record.settings"
                                        :required="false"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Video Content") }}</template>
                                    </video-input>

                                    <form-editor
                                        id="description"
                                        name="description"
                                        v-model="edited_record.description"
                                        :required="false"
                                        size="lg"
                                        :full-height="false"
                                        class="mt-4"
                                        @input="save"
                                    >
                                        <template v-slot:label>
                                            {{ translate("Description (for teachers)") }}
                                            <div class="text-uppercase mt-1">{{ translate("English") }}</div>
                                        </template>
                                    </form-editor>

                                    <form-editor
                                        id="fr_description"
                                        name="description"
                                        :value="field_translation('description').value"
                                        :required="false"
                                        size="lg"
                                        :full-height="false"
                                        class="mt-4"
                                        @input="save_field_translation('description', $event)"
                                    >
                                        <template v-slot:label>
                                            <div class="text-uppercase">{{ translate("French") }}</div>
                                        </template>
                                    </form-editor>
                                </div>
                            </div>
                        </div>
                        <div class="column col-5 col-md-6 col-sm-12">
                            <curricula
                                :activity="edited_record"
                                class="mt-2"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="!edited_record.id && !has_addl_info_panel"
                    class="card-footer text-center"
                >
                    <default-button
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="$router.go(-1)"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="!!creating"
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="create"
                    >
                        {{ translate("Save") }}
                    </default-button>
                </div>
            </div>

            <div
                v-if="has_addl_info_panel"
                class="card page-content-section"
            >
                <div class="card-header">
                    <div
                        :class="{ 'text-center': (edited_record.type === constants('asset_types', 'TYPE_GLOSSARY').id) }"
                        class="card-title h5"
                    >
                        <span v-if="edited_record.type === constants('asset_types', 'TYPE_GAME').id">{{ translate("Game Information") }}</span>
                        <span v-else-if="edited_record.type === constants('asset_types', 'TYPE_GLOSSARY').id">{{ translate("Glossary Terms") }}</span>
                    </div>
                </div>
                <div class="card-body">
                    <div
                        v-if="edited_record.type === constants('asset_types', 'TYPE_GAME').id"
                        class="columns"
                    >
                        <div
                            v-if="has_game_info"
                            class="column col-3 col-md-4 col-sm-12"
                        >
                            <form-group
                                id="number_of_levels"
                                name="number_of_levels"
                                :editable="false"
                                :required="false"
                            >
                                <template v-slot:empty-value>
                                    {{ translate("1 level:::{n} levels", { n: topic.specs.levels }, topic.specs.levels) }}
                                </template>
                            </form-group>
                            <form-group
                                id="with_text"
                                name="with_text"
                                :value="true"
                                :editable="false"
                                :required="false"
                            >
                                <template v-slot:empty-value>
                                    <span v-if="topic.specs.has_text">{{ translate("Text only, or Text and audio") }}</span>
                                    <span v-else>{{ translate("Audio only") }}</span>
                                </template>
                            </form-group>
                        </div>
                        <div
                            class="column col-9 col-md-8 col-sm-12"
                        >
                            <div class="columns">
                                <form-input
                                    id="path"
                                    name="path"
                                    v-model="edited_record.path"
                                    :required="true"
                                    placeholder="SamAmuse/my-game"
                                    :editable="!edited_record || !edited_record.id"
                                    :saving="saving('path')"
                                    :error="has_error('path')"
                                    class="column col-md-6 col-sm-12"
                                    @input="maybe_save_path"
                                >
                                    <template v-slot:label>
                                        <strong>{{ translate("BitBucket Project") }}</strong>
                                        <span
                                            v-if="!!edited_record.id && edited_record.is_installing"
                                            class="ml-1 text-gray"
                                        >
                                            {{ translate("Installing...") }}
                                        </span>
                                        <default-button
                                            v-else-if="!!edited_record.id && !!edited_record.path"
                                            flavor="link"
                                            size="sm"
                                            :title="translate('Re-install game')"
                                            @click.prevent="reinstall"
                                        >
                                            <open-icon
                                                glyph="sync"
                                                :spin="reinstalling"
                                            />
                                        </default-button>
                                    </template>
                                </form-input>

                                <form-dropdown
                                    v-if="languages.length > 1"
                                    id="lang"
                                    name="lang"
                                    :value="edited_record.lang"
                                    :options="languages"
                                    :required="true"
                                    :editable="!edited_record || !edited_record.id"
                                    class="column col-md-6 col-sm-12"
                                    @input="save"
                                >
                                    <template v-slot:label><strong>{{ translate("Language") }}</strong></template>
                                </form-dropdown>

                                <form-dropdown
                                    v-if="themes.length > 1"
                                    id="theme"
                                    name="theme"
                                    :value="edited_record.theme"
                                    :options="themes"
                                    label-field="display_name"
                                    :required="true"
                                    :editable="!edited_record || !edited_record.id"
                                    class="column col-md-6 col-sm-12"
                                    @input="save"
                                >
                                    <template v-slot:label><strong>{{ translate("Theme") }}</strong></template>
                                </form-dropdown>

                                <form-dropdown
                                    v-if="topics.length > 1"
                                    id="topic"
                                    name="topic"
                                    :value="edited_record.topic"
                                    :options="topics"
                                    :required="true"
                                    :editable="!edited_record || !edited_record.id"
                                    class="column col-md-6 col-sm-12"
                                    @input="save"
                                >
                                    <template v-slot:label><strong>{{ translate("Topic") }}</strong></template>
                                </form-dropdown>
                            </div>
                        </div>
                    </div>
                    <glossary-terms
                        v-else-if="!!edited_record.id && (edited_record.type === constants('asset_types', 'TYPE_GLOSSARY').id)"
                        :glossary-id="edited_record.id"
                        :editable="true"
                    />

                    <div
                        v-if="!edited_record.id"
                        class="card-footer text-center"
                    >
                        <default-button
                            :disabled="!!creating || saving('path')"
                            class="mx-2"
                            @click.prevent="$router.go(-1)"
                        >
                            {{ translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="primary"
                            :waiting="!!creating"
                            :disabled="!!creating || saving('path')"
                            class="mx-2"
                            @click.prevent="create"
                        >
                            {{ translate("Save") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { is_record_editor, has_translatable_fields } from "@/custom/mixins"
import { handles_uploads } from "@/nibnut/mixins"

import {
    BaseForm,
    FormGroup,
    FormToggleInput,
    FormDropdown,
    FormTagInput,
    FormInput,
    FormEditor,
    ImageUploadInput,
    // UploadInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    GlossaryTerms,
    VideoInput
} from "@/custom/components"
import Curricula from "./Curricula"

export default {
    mixins: [is_record_editor, has_translatable_fields, handles_uploads],
    components: {
        BaseForm,
        FormGroup,
        FormToggleInput,
        FormDropdown,
        FormTagInput,
        FormInput,
        FormEditor,
        ImageUploadInput,
        // UploadInput,
        VideoInput,
        DefaultButton,
        OpenIcon,
        GlossaryTerms,
        Curricula
    },
    watch: {
        "held.image_url": "regenerate_held_image"
    },
    created () {
        this.$store.dispatch(
            "FETCH_RECORDS",
            {
                entity: "tag",
                query: {
                    taxonomy: this.constants("taxonomies", "TAXONOMY_SUBJECT").id
                }
            }
        )
    },
    methods: {
        shell_record_context () {
            return { audience_role: this.constants("roles", "ROLE_STUDENT").id }
        },
        create () {
            this.creating = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: this.edited_record
            }).then(record => {
                const completed = () => {
                    this.creating = false
                    this.$router.replace({ name: "activity.list" })
                }
                /*
                const upload_pdf = () => {
                    if((record.type === this.constants("asset_types", "TYPE_GLOSSARY").id) && !!this.held.pdf_url) {
                        this.record_id = record.id
                        this.upload_file("pdf_url", this.held.pdf_url).then(() => {
                            completed()
                        }).catch(error => {
                            this.receive_error(error)
                            this.creating = false
                        })
                    } else completed()
                }
                */
                const save_translations = () => {
                    const translation = this.field_translation_for_classname(this.classname, 0, "description")
                    if(translation && translation.value) {
                        this.save_field_translation("description", translation.value).then(() => {
                            // upload_pdf()
                            completed()
                        })
                    } else completed() // else upload_pdf()
                }

                this.record_id = record.id
                if(this.held.image_url) {
                    this.upload_file("image_url", this.held.image_url).then(() => {
                        save_translations()
                    }).catch(error => {
                        this.receive_error(error)
                        this.creating = false
                    })
                } else save_translations()
            }).catch(error => {
                this.receive_error(error)
                this.creating = false
            })
        },
        maybe_save_path (value, field) {
            if(!!this.edited_record.id || !!value) this.save(value, field)
        },
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value

                if(this.edited_record.id) return this.save_field(this.edited_record[field], field)
                else if(!this.edited_record.id && (this.edited_record.type === this.constants("asset_types", "TYPE_GAME").id)) {
                    if(field === "path") {
                        this.edited_record.meta = null
                        return this.$store.dispatch(
                            "RECORDS_ACTION",
                            {
                                entity: "asset",
                                action: "game-meta",
                                data: { path: this.edited_record.path },
                                passthru: true
                            }
                        ).then(response => {
                            this.edited_record.meta = response.meta || null
                            let theme = null
                            if(!!this.edited_record.meta && !!this.edited_record.meta.themes) {
                                const themes = this.themes
                                if(themes.length) theme = themes[0]
                            }
                            this.save(theme.id, "theme")
                        }).catch(this.receive_error)
                    } else if(field === "theme") {
                        let topic = null
                        if(!!this.edited_record.theme && !!this.edited_record.meta && !!this.edited_record.meta.themes && !!this.edited_record.meta.themes[this.edited_record.theme] && !!this.edited_record.meta.themes[this.edited_record.theme].topics) {
                            const topics = this.topics
                            if(topics.length) topic = topics[0]
                        }
                        this.save(topic.id, "topic")
                    }
                }
            }
            return Promise.resolve()
        },
        toggle_publish_at () {
            if(this.edited_record.publish_at) this.save(null, "publish_at")
            else this.save(this.$moment().format("YYYY-MM-DD HH:mm:ss"), "publish_at")
        },
        upload_accepts (name, filename = null) {
            /*
            if(name === "pdf_url") {
                if(filename) return !!filename.match(/\.pdf$/i)
                return "application/pdf,.pdf"
            }
            */
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.record_id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        regenerate_held_image () {
            this.generate_held_image("image_url")
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.translate("Do you really want to delete this activity? It will no longer be available to teachers and students, and all analytics linked to the activity will also be deleted. There is no undo!"),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.$router.push({ name: "activity.list" })
        },
        reinstall () {
            this.reinstalling = true
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: this.entity,
                    id: this.edited_record.id,
                    action: "install",
                    method: "post"
                }
            ).then(() => {
                this.$success(this.translate("Installation scheduled. You will be notified by email when it completes."))
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.reinstalling = false
            })
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        types () {
            if(!this.edited_record || !this.edited_record.audience_role) return []
            return Object.values(this.constants("asset_types")).filter(asset_type => !asset_type.audience_role || (asset_type.audience_role === this.edited_record.audience_role))
        },
        themes () {
            if(!!this.edited_record && !!this.edited_record.meta && !!this.edited_record.meta.themes) {
                const themes = Object.values(this.edited_record.meta.themes)
                return themes.map(theme => {
                    theme.display_name = theme.name
                    if(themes.find(theme2 => (theme.id !== theme2.id) && (theme.name === theme2.name))) theme.display_name = `${theme.name} (${theme.id})`
                    return theme
                })
            }
            return []
        },
        theme () {
            if(!!this.edited_record && !!this.edited_record.theme && !!this.edited_record.meta && !!this.edited_record.meta.themes && !!this.edited_record.meta.themes[this.edited_record.theme]) return this.edited_record.meta.themes[this.edited_record.theme]
            return {}
        },
        topics () {
            const theme = this.theme
            if(theme.topics) return Object.values(theme.topics)
            return []
        },
        topic () {
            const theme = this.theme
            if(!!this.edited_record && !!this.edited_record.topic && !!theme.topics && !!theme.topics[this.edited_record.topic]) return theme.topics[this.edited_record.topic]
            return {}
        },
        languages () {
            const topic = this.topic
            const lang = topic.lang || ["fr"]
            return lang.map(id => {
                let name = id
                switch (id) {
                case "fr":
                    name = this.translate("French")
                    break
                case "en":
                    name = this.translate("English")
                    break
                }
                return { id, name }
            })
        },
        holding_mode () {
            return !this.record_id
        },
        is_video () {
            if(this.edited_record) return (this.edited_record.type === this.constants("asset_types", "TYPE_VIDEO").id)
            return false
        },
        has_addl_info_panel () {
            if(this.edited_record) {
                if(this.edited_record.type === this.constants("asset_types", "TYPE_GAME").id) return true
                return !!this.edited_record.id && (this.edited_record.type === this.constants("asset_types", "TYPE_GLOSSARY").id)
            }
            return false
        },
        has_game_info () {
            if(this.edited_record && (this.edited_record.type === this.constants("asset_types", "TYPE_GAME").id)) {
                const topic = this.topic
                return !!topic && !!topic.specs
            }
            return false
        },
        subjects () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_SUBJECT").id
            return orderBy(this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy), "name", "asc")
        }
    },
    data () {
        return {
            entity: "asset",
            entityName: "Activity:::Activities",
            relation_ids: ["curriculum", "asset_grade", "curriculum_item", "asset_grade_curriculum_item", "field_translation"],
            classname: "App\\Asset",
            creating: false,
            reinstalling: false
        }
    }
}
</script>

<style lang="scss">
#activity-editor {
    & > .page-title {
        display: flex;
        align-items: center;

        & > h1 {
            flex: 1 1 auto;
        }
        & > .form-group {
            flex: 0 1 auto;
        }
    }

    .card.full-height {
        height: 100%;
    }
}
</style>
