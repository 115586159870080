<template>
    <div
        v-if="!records.length"
        class="empty"
    >
        <p class="empty-title h5">{{ $root.translate("It takes a village...") }}</p>
        <p class="empty-subtitle">{{ $root.translate("You are currently the only one with access to your group and students.") }}</p>
        <div class="empty-action">
            <default-button
                flavor="link"
                @click.prevent
                v-clipboard:copy="group.invitation_url"
                v-clipboard:success="clipboard_copied"
            >
                <open-icon glyph="clipboard" class="mr-2" />
                {{ invitation_url }}
            </default-button>
        </div>
        <div class="text-small mt-2">
            {{ $root.translate("Copy this link and send it to people you would like to work with. Once they join, you will be able to control their access level here.") }}
        </div>
    </div>
    <div v-else>
        <data-table
            id="collaborators"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :clickable="false"
            :searchable="false"
            @sort="sort_by"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    <open-icon
                        v-if="(state.total > 1) && row_is_owner(row)"
                        glyph="star"
                        class="text-primary mx-2"
                    />
                    <default-button
                        v-else-if="(state.total > 1) && can_change_ownership"
                        flavor="link"
                        size="sm"
                        :title="$root.translate('Make this collaborator the owner of the group')"
                        @click.stop.prevent="maybe_change_ownership(row)"
                    >
                        <open-icon
                            glyph="star"
                            :class="{ 'text-primary': row_is_owner(row), 'text-gray': !row_is_owner(row) }"
                        />
                    </default-button>
                    {{ row.name }}
                </h6>
                <form-dropdown
                    :id="`role-${row.id}`"
                    name="role"
                    :value="row.role"
                    :options="roles"
                    :required="true"
                    size="sm"
                    :editable="!row_is_owner(row) && is_group_admin"
                    @input="save_row(row, $event, 'role')"
                >
                    <template v-slot:label>{{ $root.translate("Role") }}</template>
                </form-dropdown>
                <default-button
                    v-if="!row_is_owner(row) && is_group_admin"
                    flavor="link"
                    color="error"
                    :title="$root.translate('Revoke access to this group and its students')"
                    class="mt-8"
                    @click.stop.prevent="revoke_group_access(row)"
                >
                    <open-icon glyph="ban" class="mr-2" /> {{ $root.translate('Revoke access') }}
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'name'"
                >
                    <open-icon
                        v-if="(state.total > 1) && row_is_owner(row)"
                        glyph="star"
                        class="text-primary mx-2"
                    />
                    <default-button
                        v-else-if="(state.total > 1) && can_change_ownership"
                        flavor="link"
                        size="sm"
                        :title="$root.translate('Make this collaborator the owner of the group')"
                        @click.stop.prevent="maybe_change_ownership(row)"
                    >
                        <open-icon
                            glyph="star"
                            :class="{ 'text-primary': row_is_owner(row), 'text-gray': !row_is_owner(row) }"
                        />
                    </default-button>
                    {{ row.name }}
                </div>
                <div v-else-if="field === 'role'">
                    <form-dropdown
                        :id="`role-${row.id}`"
                        name="role"
                        :value="row.role"
                        :options="roles"
                        :required="true"
                        size="sm"
                        :editable="!row_is_owner(row) && is_group_admin"
                        @input="save_row(row, $event, 'role')"
                    />
                </div>
                <div v-else-if="field === '_buttons'" class="text-right">
                    <default-button
                        v-if="!row_is_owner(row) && is_group_admin"
                        flavor="link"
                        color="error"
                        :title="$root.translate('Revoke access to this group and its students')"
                        @click.stop.prevent="revoke_group_access(row)"
                    >
                        <open-icon glyph="ban" />
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <div v-if="!!group.invitation_url" class="text-center bg-gray pt-2 pb-4 mt-4">
            <default-button
                flavor="link"
                class="copy-link-url"
                @click.prevent
                v-clipboard:copy="group.invitation_url"
                v-clipboard:success="clipboard_copied"
            >
                <open-icon glyph="clipboard" class="mr-2" />
                {{ invitation_url }}
            </default-button>
            <div class="text-small mt-2">
                {{ $root.translate("Copy this link and send it to people you would like to work with. Once they join, you will be able to control their access level here.") }}
            </div>
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import { is_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import { FormDropdown, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "Collaborators",
    mixins: [is_data_table_source, handles_saving, confirms],
    components: {
        FormDropdown,
        DefaultButton,
        OpenIcon
    },
    methods: {
        row_is_owner (row) {
            return !!row && (row.id === this.group.owner_id)
        },
        save_row (row, value, field) {
            this.save_data_for_record_id("group_user", row.id, { [field]: value })
        },
        clipboard_copied () {
            this.$success(this.$root.translate("URL Copied!"))
        },
        maybe_change_ownership (collaborator) {
            this.action_record = collaborator
            if(this.is_at_least_administrator) this.change_ownership()
            else {
                this.confirm(
                    {
                        type: "error",
                        title: this.$root.translate("Make {name} the new group owner", { name: this.action_record.name }),
                        message: this.$root.translate("Do you really want to make {name} the new owner of this group? Since you are currently the owner, you will not be able to change this back.", { name: this.action_record.name }),
                        cancel: this.$root.translate("Cancel"),
                        ok: this.$root.translate("Change group owner")
                    },
                    "change-ownership"
                )
            }
        },
        revoke_group_access (collaborator) {
            this.action_record = collaborator
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Revoke {name}'s access", { name: this.action_record.name }),
                    message: this.$root.translate("Do you really want to revoke {name}'s access to this group? They will no longer have access to the group's students.", { name: this.action_record.name }),
                    cancel: this.$root.translate("Cancel"),
                    ok: this.$root.translate("Revoke access")
                },
                "revoke-access"
            )
        },
        confirmed () {
            if(this.confirming === "change-ownership") this.change_ownership()
            else if(this.confirming === "revoke-access") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "group_user",
                        id: this.action_record.id
                    }
                ).then(() => {
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        },
        change_ownership () {
            if(this.action_record) {
                this.save_data_for_record_id("group", this.group.id, { owner_id: this.action_record.id, relation_ids: ["group_user"] })
            }
        }
    },
    computed: {
        state_identifier () {
            return "collaborators"
        },
        rows () {
            return this.records
        },
        roles () {
            const min_role = this.constants("group_user_types", "ROLE_STUDENT").id
            return orderBy(Object.values(this.constants("group_user_types")).filter(type => type.id > min_role), "name", "asc")
        },
        is_group_admin () {
            const min_role = this.constants("group_user_types", "ROLE_ADMIN").id
            const group_user = this.entity_records("group_user").find(group_user => {
                return (group_user.user_id === this.profile_id) && (group_user.group_id === this.group.id) && (group_user.role >= min_role)
            })
            return this.is_at_least_administrator || !!group_user
        },
        can_change_ownership () {
            const group_user = this.entity_records("group_user").find(group_user => {
                return (group_user.user_id === this.profile_id) && (group_user.group_id === this.group.id) && (this.group.owner_id === group_user.id)
            })
            return this.is_at_least_administrator || !!group_user
        },
        invitation_url () {
            if(!this.group || !this.group.invitation_url) return ""
            const max_length = 60
            if(!!this.group && (this.group.invitation_url.length > max_length)) {
                const ellipsis = "..."
                const shortened_length = max_length - ellipsis.length
                const prefix_length = Math.round(shortened_length * 0.666)
                const suffix_length = shortened_length - prefix_length
                return `${this.group.invitation_url.substr(0, prefix_length)}${ellipsis}${this.group.invitation_url.substr(this.group.invitation_url.length - suffix_length, suffix_length)}`
            }
            return this.group.invitation_url
        }
    },
    props: {
        group: {
            type: Object,
            required: true
        },
        records: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                role: { label: this.translate("Role"), sort: null, type: "amount" },
                _buttons: { label: " ", sort: false }
            },

            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            action_record: null
        }
    }
}
</script>

<style lang="scss">
.btn.copy-link-url {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
</style>
