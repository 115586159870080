import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { addl_profile_utilities } from "@/custom/mixins"

import { DefaultButton, OpenIcon } from "@/nibnut/components"

const is_dashboard_panel = {
    mixins: [addl_profile_utilities],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        statused (status) {
            this.page_status = status
            this.waiting = false
        },
        page_autoscroll () {
            if(!!this.$route.meta && !!this.$route.meta.scroll_position && (this.$route.meta.scroll_position !== true)) {
                setTimeout(() => {
                    window.scrollTo({ top: this.$route.meta.scroll_position.y, left: this.$route.meta.scroll_position.x })
                }, 250)
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        tiny_screen () {
            return !!this.$mq.match(/^(xs|sm)$/i)
        },
        raw_groups () {
            return this.entity_records("group", this.groupIds)
        },
        groups () {
            return orderBy(this.raw_groups, "name", "asc")
        },
        group () {
            if(!this.groupId) return {}
            return this.raw_groups.find(group => group.id === this.groupId) || {}
        },
        raw_group_users () {
            if(!this.groupId) return []
            const role = this.constants("group_user_types", "ROLE_STUDENT").id
            return this.entity_records("group_user").filter(group_user => {
                return (group_user.role === role) && (group_user.group_id === this.groupId)
            })
        },
        group_users () {
            return orderBy(this.raw_group_users, "username", "asc")
        }
    },
    props: {
        groupId: {
            type: Number,
            required: true
        },
        groupIds: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            page_status: 200,
            waiting: true
        }
    }
}

export default is_dashboard_panel
