<template>
    <page
        id="access-cards"
        :title="$root.translate('Access Cards')"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title><span></span></template>
        <div class="columns">
            <div
                v-for="student in students"
                :key="student.id"
                class="column col-4 col-lg-12"
            >
                <access-card
                    :user="student"
                    class="m-1"
                />
            </div>
        </div>
        <div class="page-break"></div>
        <div class="columns reversed">
            <div
                v-for="student in students"
                :key="student.id"
                class="column col-4 col-lg-12"
            >
                <access-card
                    class="m-1"
                />
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { profile_utilities } from "@/nibnut/mixins"
import { is_page } from "@/custom/mixins"

import { AccessCard } from "@/custom/components"

export default {
    mixins: [is_page, profile_utilities],
    components: {
        AccessCard
    },
    mounted () {
        this.maybe_load()
    },
    watch: {
        profile_id: "maybe_load"
    },
    methods: {
        maybe_load () {
            if(this.profile_id) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "group_user",
                        query: {
                            group_id: this.$route.params.id,
                            student_id: this.$route.query.student_id || 0,
                            per_page: 0,
                            fields: ["fieldset::access-card"]
                        }
                    }
                ).then(response => {
                    this.current_records_ids = response.record_ids
                    setTimeout(() => {
                        window.print()
                    }, 500)
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        students () {
            return this.entity_records("group_user", this.current_records_ids)
        }
    },
    data () {
        return {
            current_records_ids: []
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#access-cards {
    .columns.reversed {
        justify-content: flex-end;
    }

    @media print {
        .page-body {
            & > .columns {
                display: block;
            }
        }
    }
}
</style>
