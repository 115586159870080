<template>
    <ul v-if="!!curriculum_items.length">
        <li
            v-for="curriculum_item in curriculum_items"
            :key="curriculum_item.id"
        >
            <form-toggle-input
                :id="`curriculum_item-${curriculum_item.id}`"
                name="item_toggle"
                type="checkbox"
                :indeterminate="is_partially_selected(curriculum_item)"
                :value="!!grade_curriculum_item_by_curriculum_id(curriculum_item) || is_fully_selected(curriculum_item)"
                :disabled="!editable"
                @input="curriculum_item_toggle(curriculum_item)"
            >
                {{ curriculum_item.name }}
            </form-toggle-input>

            <grade-curriculum-item
                :parent-id="curriculum_item.id"
                :curriculum-id="curriculumId"
                :asset-grade="assetGrade"
                :selection="selection"
                :editable="!grade_curriculum_item_by_curriculum_id(curriculum_item)"
            />
        </li>
    </ul>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { FormToggleInput } from "@/nibnut/components"

export default {
    name: "GradeCurriculumItem",
    components: {
        FormToggleInput
    },
    methods: {
        grade_curriculum_item_by_curriculum_id (curriculum_item) {
            return this.selection.find(grade_curriculum_item => grade_curriculum_item.curriculum_item_id === curriculum_item.id)
        },
        curriculum_item_toggle (curriculum_item) {
            const grade_curriculum_item = this.grade_curriculum_item_by_curriculum_id(curriculum_item)
            if(grade_curriculum_item) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "asset_grade_curriculum_item",
                        id: grade_curriculum_item.id
                    }
                ).catch(error => {
                    this.$error(error.message)
                })
            } else {
                this.$store.dispatch(
                    "CREATE_RECORD",
                    {
                        entity: "asset_grade_curriculum_item",
                        data: {
                            asset_grade_id: this.assetGrade.id,
                            curriculum_item_id: curriculum_item.id,
                            relation_ids: ["asset_grade_curriculum_item"]
                        }
                    }
                ).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        children_items (curriculum_item) {
            return this.entity_records("curriculum_item").filter(child => (child.curriculum_id === this.curriculumId) && (child.parent_id === curriculum_item.id) && (child.grade === curriculum_item.grade))
        },
        selected_children_items (curriculum_item, children_item_ids = null) {
            if(!children_item_ids) children_item_ids = this.children_items(curriculum_item).map(child => child.id)
            if(!children_item_ids.length) return false
            return this.selection.filter(grade_curriculum_item => children_item_ids.indexOf(grade_curriculum_item.curriculum_item_id) >= 0)
        },
        is_partially_selected (curriculum_item) {
            const children_item_ids = this.children_items(curriculum_item).map(child => child.id)
            if(!children_item_ids.length) return false
            const selected_items = this.selected_children_items(curriculum_item, children_item_ids)
            return !!selected_items.length && (children_item_ids.length !== selected_items.length)
        },
        is_fully_selected (curriculum_item) {
            const children_item_ids = this.children_items(curriculum_item).map(child => child.id)
            if(!children_item_ids.length) return false
            const selected_items = this.selected_children_items(curriculum_item, children_item_ids)
            return !!selected_items.length && (children_item_ids.length === selected_items.length)
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        raw_curriculum_items () {
            return this.entity_records("curriculum_item").filter(curriculum_item => (curriculum_item.curriculum_id === this.curriculumId) && (curriculum_item.parent_id === this.parentId) && (curriculum_item.grade === this.assetGrade.grade))
        },
        curriculum_items () {
            return orderBy(this.raw_curriculum_items, "name", "asc")
        }
    },
    props: {
        parentId: {
            type: Number,
            required: true
        },
        assetGrade: {
            type: Object,
            required: true
        },
        curriculumId: {
            type: Number,
            required: true
        },
        selection: {
            type: Array,
            required: true
        },
        editable: {
            type: Boolean,
            default: true
        }
    }
}
</script>
