<template>
    <page
        id="groups-list"
        :title="$root.translate('My Groups')"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <base-groups-list
            :user="{ id: profile_id, _global: true }"
        />
    </page>
</template>

<script type="text/javascript">
import { is_page } from "@/custom/mixins"
import { profile_utilities } from "@/nibnut/mixins"

import { GroupsList as BaseGroupsList } from "@/custom/components"

export default {
    mixins: [is_page, profile_utilities],
    components: {
        BaseGroupsList
    }
}
</script>
