<template>
    <page
        id="coupon-editor"
        :title="page_title"
        :back-navigation-fallback="{ title: $root.translate(entityName, {}, 2), href: { name: 'coupon.list' } }"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            @submit="create"
        >
            <div class="card">
                <div class="card-body">
                    <div class="columns">
                        <div class="column col-4 col-sm-12">
                            <form-input
                                id="name"
                                name="name"
                                v-model="edited_record.name"
                                :required="true"
                                :disabled="!!edited_record.id"
                                :saving="saving('name')"
                                :error="has_error('name')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Name") }}</template>
                                <template v-slot:hint>{{ $root.translate("This will appear on customers' receipts and invoices.") }}</template>
                            </form-input>
                        </div>
                        <div class="column col-4 col-sm-12">
                            <form-input
                                v-if="!!edited_record.id"
                                id="fr_name"
                                name="name"
                                :value="field_translation('name').value"
                                :required="false"
                                @input="save_field_translation('name', $event)"
                            >
                                <template v-slot:label>{{ $root.translate("Name (FR)") }}</template>
                            </form-input>
                        </div>
                        <div class="column col-4 col-sm-12">
                            <form-input
                                id="stripe_id"
                                name="stripe_id"
                                v-model="edited_record.stripe_id"
                                :required="false"
                                :disabled="!!edited_record.id"
                                :placeholder="$root.translate('If left empty, a random coupon code will be generated')"
                                :saving="saving('stripe_id')"
                                :error="has_error('stripe_id')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Code") }}</template>
                                <template v-slot:hint>{{ $root.translate("This is the code your customers will use to get the rebate.") }}</template>
                            </form-input>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column col-2 col-md-6 col-sm-12">
                            <form-input
                                id="off"
                                name="off"
                                :value="off"
                                type="float"
                                :min="1"
                                :max="percent_mode ? 100 : 100000"
                                :required="false"
                                :disabled="!!edited_record.id"
                                :saving="saving('amount_off') || saving('percent_off')"
                                :error="has_error('amount_off') || has_error('percent_off')"
                                @input="save_off"
                            >
                                <template v-slot:label>{{ $root.translate("Discount") }}</template>
                                <template v-slot:left_addon>
                                    <default-button
                                        :active="!percent_mode"
                                        class="input-group-btn"
                                        @click.prevent="percent_mode = false"
                                    >
                                        <open-icon glyph="dollar-sign" />
                                    </default-button>
                                </template>
                                <template v-slot:right_addon>
                                    <default-button
                                        :active="percent_mode"
                                        class="input-group-btn"
                                        @click.prevent="percent_mode = true"
                                    >
                                        <open-icon glyph="percent" />
                                    </default-button>
                                </template>
                            </form-input>
                        </div>
                        <div class="column col-4 col-md-6 col-sm-12">
                            <div class="duration-container">
                                <form-dropdown
                                    id="duration"
                                    name="duration"
                                    :value="edited_record.duration"
                                    :options="durations"
                                    :required="true"
                                    :disabled="!!edited_record.id"
                                    :error="has_error('duration')"
                                    :saving="saving('duration')"
                                    class="durations"
                                    @input="save_duration"
                                >
                                    <template v-slot:label>{{ $root.translate("Duration") }}</template>
                                </form-dropdown>
                                <form-input
                                    v-if="edited_record.duration === constants('durations', 'DURATION_REPEAT').id"
                                    id="duration_in_months"
                                    name="duration_in_months"
                                    v-model="edited_record.duration_in_months"
                                    type="number"
                                    :min="1"
                                    :required="false"
                                    :disabled="!!edited_record.id"
                                    :saving="saving('duration_in_months')"
                                    :error="has_error('duration_in_months')"
                                    class="duration_in_months ml-4"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ $root.translate("Duration in months") }}</template>
                                    <template v-slot:right_addon>
                                        <span class="input-group-addon">{{ $root.translate("month(s)") }}</span>
                                    </template>
                                </form-input>
                            </div>
                        </div>
                        <div class="column col-3 col-md-6 col-sm-12">
                            <form-input
                                id="max_redemptions"
                                name="max_redemptions"
                                v-model="edited_record.max_redemptions"
                                type="number"
                                :min="0"
                                :required="false"
                                :disabled="!!edited_record.id"
                                :saving="saving('max_redemptions')"
                                :error="has_error('max_redemptions')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Max. Redemptions") }}</template>
                                <template v-slot:hint>{{ $root.translate("Zero = unlimited") }}</template>
                            </form-input>
                        </div>
                        <div class="column col-3 col-md-6 col-sm-12">
                            <form-date-input
                                id="redeem_by"
                                name="redeem_by"
                                v-model="edited_record.redeem_by"
                                :min="$moment().startOf('day').add(1, 'day')"
                                :required="false"
                                :disabled="!!edited_record.id"
                                :saving="saving('redeem_by')"
                                :error="has_error('redeem_by')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Expires on") }}</template>
                                <template v-slot:hint>{{ $root.translate("Leave empty if valid forever") }}</template>
                            </form-date-input>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!edited_record.id"
                    class="card-footer text-center"
                >
                    <default-button
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="$router.go(-1)"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="!!creating"
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="create"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </div>
        </base-form>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor, has_translatable_fields } from "@/custom/mixins"

import {
    BaseForm,
    FormInput,
    FormDropdown,
    FormDateInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_record_editor, has_translatable_fields],
    components: {
        BaseForm,
        FormInput,
        FormDropdown,
        FormDateInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        create () {
            this.creating = true
            const { is_valid, computed_redemptions, id, ...data } = this.edited_record
            if(this.percent_mode) {
                data.amount_off = 0
                data.percent_off = parseFloat(data.percent_off) * 100
            } else {
                data.percent_off = 0
                data.amount_off = parseFloat(data.amount_off) * 100
            }
            if(data.redeem_by) data.redeem_by = this.$moment(data.redeem_by).format("YYYY-MM-DD")

            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data
            }).then(record => {
                this.$router.replace({ name: `${this.entity}.list` })
            }).catch(error => {
                this.receive_error(error)
            }).then(() => {
                this.creating = false
            })
        },
        save_off (value) {
            if(this.percent_mode) this.save(value, "percent_off")
            this.save(value, "amount_off")
        },
        save_duration (value) {
            if(this.edited_record) {
                this.edited_record.duration = value
                this.edited_record.duration_in_months = 1
                if(value === this.constants("durations", "DURATION_FOREVER").id) this.edited_record.duration_in_months = 0
                if(this.edited_record.id) return this.save_data_for_record_id(this.entity, this.edited_record.id, { duration: this.edited_record.duration, duration_in_months: this.edited_record.duration_in_months })
            }
            return Promise.resolve()
        }
    },
    computed: {
        durations () {
            return Object.values(this.constants("durations"))
        },
        off () {
            if(this.percent_mode) return this.edited_record.percent_off
            return this.edited_record.amount_off
        }
    },
    data () {
        return {
            entity: "coupon",
            entityName: "Coupon:::Coupons",
            classname: "App\\Coupon",
            percent_mode: true,
            creating: false
        }
    }
}
</script>

<style lang="scss">
#coupon-editor {
    .duration-container {
        display: flex;

        label.form-label {
            visibility: hidden;
        }

        & > .form-group.durations {
            flex: 1 0 50%;
        }
        & > .form-group.duration_in_months {
            flex: 0 1 auto;
        }
    }
}
</style>
