<template>
    <page
        id="settings"
        :title="$root.translate('Settings')"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <tabs
            :tabs="tabs"
            :current-tab-id="current_tab_id"
            class="mt-4"
            @click="current_tab_id = $event"
        >
            <general-panel
                v-if="current_tab_id === 'general'"
                :record="edited_record || {}"
                class="mt-8"
            />
            <finances-panel
                v-if="current_tab_id === 'finances'"
                :record="edited_record || {}"
                class="mt-8"
            />
            <users-panel
                v-if="current_tab_id === 'users'"
                :record="edited_record || {}"
                class="mt-8"
            />
            <institutions-panel
                v-if="current_tab_id === 'institutions'"
                :record="edited_record || {}"
                class="mt-8"
            />
        </tabs>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor } from "@/custom/mixins"

import { Tabs } from "@/nibnut/components"
import GeneralPanel from "./settings/GeneralPanel"
import FinancesPanel from "./settings/FinancesPanel"
import UsersPanel from "./settings/UsersPanel"
import InstitutionsPanel from "./settings/InstitutionsPanel"

export default {
    mixins: [is_record_editor],
    components: {
        GeneralPanel,
        FinancesPanel,
        UsersPanel,
        InstitutionsPanel,
        Tabs
    },
    methods: {
        grab_record_id () {
            this.record_id = 1
        }
    },
    computed: {
        tabs () {
            return [
                { id: "general", title: this.$root.translate("General") },
                { id: "finances", title: this.$root.translate("Finances") },
                { id: "users", title: this.$root.translate("Users & Students") },
                { id: "institutions", title: this.$root.translate("Institutions & Curricula") }
            ]
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings",
            current_tab_id: "general"
        }
    }
}
</script>
