<template>
    <page
        id="secret"
        :title="$root.translate('Your Secret\'s Safe With Us!')"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <div v-if="!secret.id" class="empty">
            <p class="empty-title h5">{{ $root.translate("Shoot! Looks like this link has expired.") }}</p>
            <p class="empty-subtitle">{{ $root.translate("You should contact your teacher to get a new one sent to you.") }}</p>
        </div>
        <div v-else class="empty">
            <p class="empty-title h5">{{ $root.translate("Your Group Access Code is:") }}</p>
            <p class="empty-subtitle">{{ $root.translate("Make sure to save it somewhere safe: once you close this window, you will no longer have access to the access code.") }}</p>
            <p class="empty-subtitle">{{ $root.translate("If you lose your code, you will have to ask your teacher for a new secret url.") }}</p>
            <div class="empty-action">
                <default-button
                    color="primary"
                    @click.prevent
                    v-clipboard:copy="secret.content"
                    v-clipboard:success="clipboard_copied"
                >
                    <open-icon glyph="clipboard" class="mr-2" />
                    {{ secret.content }}
                </default-button>
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { is_page } from "@/custom/mixins"

import { DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    mixins: [is_page],
    components: {
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.waiting = true
        this.load()
    },
    methods: {
        load () {
            const id = this.$route.params.id
            this.$store.dispatch(
                "FETCH_RECORD",
                {
                    entity: "secret",
                    id
                }
            ).catch(() => {
                // console.error(error.message)
            }).then(() => {
                this.waiting = false
            })
        },
        clipboard_copied () {
            this.$success(this.$root.translate("Access Code Copied!"))
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        secret () {
            if(!this.$route.params.id) return {}
            return this.entity_record("secret", this.$route.params.id) || {}
        }
    }
}
</script>
